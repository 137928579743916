import * as React from "react"

const Footer = () => (
  <footer className="navbar-fixed-bottom">
    <div className="footer-copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-6">Made by <a className="white-text text-lighten-1" target="_blank" rel="noreferrer" href="https://olliedavies.co.uk/?utm_source=shottonsteel.co.uk&utm_medium=external-website&utm_campaign=built-by">Ollie Davies</a></div>
          <div className="col-md-6 text-right"><a className="white-text text-lighten-1" href="/privacy">Privacy Policy &amp; Terms of Use</a></div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer
